import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import moment from 'moment'
import * as Yup from 'yup'
import Collapse from '@material-ui/core/Collapse'

import { FiArrowRightCircle, FiClock, FiDollarSign } from 'react-icons/fi'
import { BiCake } from 'react-icons/bi'
import getValidationErrors from '../../utils/getValidationErrors'
import usePersistedState from '../../hooks/usePersistedState'
import { Valor, ValorMascarar } from '../../utils/masks'
import { formatValue } from '../../utils/formatValues'

import Header from '../../components/Header'
import Button from '../../components/Button'
import Input from '../../components/Input'

import { UserDetails, UserData } from '../../utils/interfaces'
import {
  Container,
  Content,
  Line,
  InfoContent,
  PercentualBox,
  ButtonSimulationCalc,
  ValueSimulation,
} from './styles'
import calculaIdade from '../../utils/calculaIdade'

const BemVindo: React.FC = () => {
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )
  const [idadeApos, setIdadeApos] = useState(userDetails.age)
  const [salarioValue, setSalarioValue] = useState(userDetails.salario)
  const [vlrCtbSup, setVlrCtbSup] = useState(
    userDetails.contribuicaoSuplementar > 0
      ? userDetails.contribuicaoSuplementar
      : 0,
  )
  const [fBotao, setFBotao] = useState('')
  // const [vlrCtbPatrocinadora, setVlrCtbPatrocinadora] = useState(
  //   userDetails.contribuicaoSuplementarPatrocinadora > 0
  //     ? userDetails.contribuicaoSuplementarPatrocinadora
  //     : 0,
  // )
  // const [pctCtbPatroc, setPctCtbPatroc] = useState(
  //   userDetails.pctContribuicaoPatrocinadora > 0
  //     ? userDetails.pctContribuicaoPatrocinadora
  //     : 0,
  // )
  const [pctCtbSup, setPctCtbSup] = useState(
    userDetails.pctContribuicaoSuplementar > 0
      ? userDetails.pctContribuicaoSuplementar
      : 0,
  )
  const [pctCtbAdic, setPctCtbAdic] = useState(
    userDetails.pctContribuicaoAdicional > 0
      ? userDetails.pctContribuicaoAdicional
      : 0,
  )
  const [vlrCtbAdic, setVlrCtbAdic] = useState(
    userDetails.contribuicaoAdicional > 0
      ? userDetails.contribuicaoAdicional
      : 0,
  )
  const [maxPctCtb, setMaxPctCtb] = useState(2)
  const URP = 789.9 // 753.24 // 725.23 // 687.61

  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  /* CALCULO DE CONTRIBUIÇÃO NORMAL */
  // const maxURP = URP * 7
  // let contribExcedente = 0
  // let modelContrib = 0

  // useEffect(() => {
  //   if (salarioValue !== 0 && salarioValue !== undefined) {
  //     if (salarioValue <= maxURP) {
  //       setVlrCtbPatrocinadora(0)
  //       // setPctCtbPatroc(0)
  //     } else {
  //       // eslint-disable-next-line react-hooks/exhaustive-deps
  //       contribExcedente = salarioValue - maxURP
  //       // eslint-disable-next-line react-hooks/exhaustive-deps
  //       modelContrib = contribExcedente * 0.05
  //       setVlrCtbPatrocinadora(parseFloat(modelContrib.toFixed(2)))
  //       // setPctCtbPatroc(maxPctCtb)
  //     }
  //     // setVlrCtbAdicPatroc(vlrCtbSup)
  //   }

  // // setVlrCtbTotal(parseFloat((modelContrib * 2).toFixed(2)))
  // }, [
  //   salarioValue,
  //   vlrCtbPatrocinadora,
  //   vlrCtbSup,
  //   setVlrCtbSup,
  //   setVlrCtbPatrocinadora,
  // ])

  const handleMudaContrib = useCallback(
    (pct, sal) => {
      const baseCalculo = sal - URP * 7
      const minCtb = URP * 0.14
      let valueCtb = 0
      if (sal >= URP * 7) {
        valueCtb = baseCalculo * (pct / 100)
        setPctCtbSup(pct)
        setVlrCtbSup(
          parseFloat(
            (valueCtb < minCtb && valueCtb > 0 ? minCtb : valueCtb).toFixed(2),
          ),
        )
      } else {
        valueCtb = sal * (pct / 100)
        setPctCtbSup(pct)
        setVlrCtbSup(parseFloat(valueCtb.toFixed(2)))
      }
    },
    [URP],
  )

  const handleMudaContribAdic = useCallback((pct, salario) => {
    const valueCAdic = (salario * pct) / 100
    setPctCtbAdic(pct)
    setVlrCtbAdic(valueCAdic)
  }, [])

  const mudarSalario = useCallback(
    valor => {
      const v = valor.replace(',', '').replaceAll('.', '')
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(v)) {
        setSalarioValue(salarioValue)
      } else {
        const m = Math.floor(v.length - 2)
        const a = `${v.substr(0, m)}.${v.substr(m)}`
        const f = parseFloat(a)
        setSalarioValue(f)
        handleMudaContrib(pctCtbSup, f)
        handleMudaContribAdic(pctCtbAdic, f)
      }
    },
    [
      salarioValue,
      handleMudaContrib,
      pctCtbSup,
      handleMudaContribAdic,
      pctCtbAdic,
    ],
  )

  useEffect(() => {
    if (salarioValue >= 7 * URP) {
      setMaxPctCtb(5)
    } else {
      setMaxPctCtb(2)
      setPctCtbSup(pctCtbSup > 2 ? 2 : pctCtbSup)
    }
  }, [mudarSalario, pctCtbSup, salarioValue])

  const handleSubmit = useCallback(
    async data => {
      try {
        // const now = new Date()
        const bday = data.birthdate.split('/').reverse().join('-')
        // const ano = bday.split('-')[0]
        const idadeAtual = calculaIdade(bday)
        const anosContrib = idadeApos - idadeAtual
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          birthdate: Yup.string()
            .required('Campo obrigatório')
            .min(10, 'Data de nascimento deve seguir o formato dd/mm/aaaa.')
            .test(
              '',
              'A data de nascimento não pode ser maior que hoje.',
              () => moment() > moment(bday) || data.birthdate === '',
            )
            .test(
              '',
              'Data de nascimento inválida',
              () => moment(bday).isValid() || data.birthdate === '',
            )
            .test(
              '',
              'Data de nascimento inválida',
              () => idadeAtual <= 115 || data.birthdate === '',
            ),
          age: Yup.number()
            .typeError(
              'Digite a idade desejada para a aposentadoria para realizar a simulação',
            )
            .required(
              'Digite a idade desejada para a aposentadoria para realizar a simulação',
            )
            .min(
              idadeAtual < 55 ? 55 : idadeAtual + 1,
              `Idade inválida. Por favor, escolha uma idade igual ou superior a ${
                idadeAtual < 55 ? 55 : idadeAtual + 1
              } anos`,
            )
            .max(115, 'Idade inválida.'),
          salario: Yup.string()
            .test('', 'Salário inválido.', () => salarioValue <= 500000)
            .required('Campo obrigatório'),
        })

        await schema.validate(data, { abortEarly: false })

        setUserData({
          ...userData,
          birthdate: bday,
        })
        setUserDetails({
          ...userDetails,
          salario: salarioValue,
          pctContribuicaoSuplementar: pctCtbSup,
          contribuicaoSuplementar: vlrCtbSup,
          contribuicaoSuplementarPatrocinadora: vlrCtbSup * 2,
          pctcontribuicaoSuplementarPatrocinadora: pctCtbSup,
          contribuicaoAdicional:
            pctCtbSup > 0 && pctCtbSup < maxPctCtb && salarioValue > URP * 7
              ? 0
              : vlrCtbAdic,
          pctContribuicaoAdicional:
            pctCtbSup > 0 && pctCtbSup < maxPctCtb && salarioValue > URP * 7
              ? 0
              : pctCtbAdic * 1,
          years: anosContrib,
          age: idadeApos,
          // pctContribuicaoPatrocinadora: pctCtbPatroc,
          // contribuicaoSuplementarPatrocinadora: vlrCtbSupPatroc,
        })
        fBotao === 'S' ? history.push('/simulation') : history.push('/register')
      } catch (err) {
        formRef.current?.setErrors(
          getValidationErrors(err as Yup.ValidationError),
        )
      }
    },
    [
      fBotao,
      history,
      idadeApos,
      maxPctCtb,
      pctCtbSup,
      pctCtbAdic,
      salarioValue,
      setUserData,
      setUserDetails,
      userData,
      userDetails,
      vlrCtbSup,
      vlrCtbAdic,
    ],
  )

  const minusContrib = useCallback(() => {
    let step = 0
    if (salarioValue >= URP * 7) {
      step = 0.5
    } else {
      step = 2
    }
    handleMudaContrib(pctCtbSup - step, salarioValue)
  }, [handleMudaContrib, salarioValue, pctCtbSup])

  const plusContrib = useCallback(() => {
    let step = 0
    if (salarioValue >= URP * 7) {
      step = 0.5
    } else {
      step = 2
    }
    handleMudaContrib(pctCtbSup + step, salarioValue)
  }, [handleMudaContrib, pctCtbSup, salarioValue])

  const minusContribAdic = useCallback(() => {
    handleMudaContribAdic(
      /* pctCtbAdic - 0.5 < 1 ? 0 : */
      pctCtbAdic - 0.5,
      salarioValue,
    )
  }, [handleMudaContribAdic, pctCtbAdic, salarioValue])

  const plusContribAdic = useCallback(() => {
    handleMudaContribAdic(
      /* pctCtbAdic + 0.5 < 1 ? 1 : */
      pctCtbAdic + 0.5,
      salarioValue,
    )
  }, [handleMudaContribAdic, pctCtbAdic, salarioValue])

  const handleClick = useCallback(async flag => {
    await setFBotao(flag)
    formRef.current?.submitForm()
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            age: userDetails.age,
            salario:
              userDetails.salario === undefined
                ? ''
                : ValorMascarar(userDetails.salario.toFixed(2).toString()),
            birthdate:
              userData.birthdate === undefined
                ? ''
                : userData.birthdate.toString().split('-').reverse().join('/'),
            pctContribuicaoAdic: pctCtbAdic,
          }}
        >
          <Content>
            <strong>Adesão Eletrônica</strong>
            <p>
              Invista hoje mesmo no seu futuro.
              <br />
              Vamos começar a sua adesão? Que tal simular como funciona?
              <br />
              Lembre-se que o percentual escolhido será descontado do seu
              salário.
            </p>
            <Input
              icon={BiCake}
              name="birthdate"
              placeholder="Data de nascimento"
              maxLength={10}
              mask="date"
            />
            <Input
              icon={FiClock}
              type="number"
              name="age"
              value={idadeApos}
              min={55}
              sufix="anos"
              placeholder="Qual a idade desejada para a aposentadoria?*"
              onChange={e => setIdadeApos(parseInt(e.target.value, 10))}
            />
            <Input
              icon={FiDollarSign}
              name="salario"
              mask="currency"
              type="text"
              placeholder="Qual o seu salário (R$)?**"
              onChange={e => mudarSalario(e.target.value)}
            />

            <small>
              * Elegibilidade ao benefício de aposentadoria pela Prev Pepsico a
              partir dos 55 anos de idade.
            </small>
            <small>
              ** O salário informado será utilizado para simulação da
              contribuição. A informação será validada pelo RH.
            </small>
            <small>
              {`Para salários superiores a 7 URP, o valor mínimo de contribuição
              será de 0,14 URP, equivalente a ${Valor(
                (URP * 0.14).toFixed(2),
              )}.`}
            </small>
            <small>
              Na Contribuição Suplementar de participante a empresa contribuirá
              com 200% de contrapartida.
            </small>

            <Line />
            {/* <Collapse in={salarioValue >= URP * 7} className="collapse"> */}
            <h4>Contribuição Suplementar</h4>
            <PercentualBox>
              <p>Qual percentual você quer contribuir?</p>
              <div>
                <ButtonSimulationCalc
                  type="button"
                  disabled={pctCtbSup <= 0}
                  isSelected={pctCtbSup > 0}
                  onClick={minusContrib}
                >
                  -
                </ButtonSimulationCalc>
                <ValueSimulation>{pctCtbSup}%</ValueSimulation>
                <ButtonSimulationCalc
                  type="button"
                  disabled={
                    pctCtbSup >= maxPctCtb ||
                    salarioValue === undefined ||
                    salarioValue === null
                  }
                  isSelected={pctCtbSup < maxPctCtb}
                  onClick={() => plusContrib()}
                >
                  +
                </ButtonSimulationCalc>
              </div>
            </PercentualBox>

            <InfoContent>
              <div>
                <p>Valor da contribuição suplementar do participante: </p>
                <strong>{formatValue(vlrCtbSup)}</strong>
              </div>
            </InfoContent>
            {/* </Collapse> */}
            <Collapse
              in={
                (pctCtbSup === 0 || pctCtbSup === maxPctCtb) && salarioValue > 0
              }
              className="collapse"
            >
              <Line />
              <h4>Contribuição Adicional</h4>
              <PercentualBox>
                <p>Qual percentual você quer contribuir?</p>
                <div>
                  <ButtonSimulationCalc
                    type="button"
                    disabled={pctCtbAdic <= 0}
                    isSelected={pctCtbAdic > 0}
                    onClick={minusContribAdic}
                  >
                    -
                  </ButtonSimulationCalc>
                  <ValueSimulation>{pctCtbAdic}%</ValueSimulation>
                  <ButtonSimulationCalc
                    type="button"
                    disabled={pctCtbAdic >= 20}
                    isSelected={pctCtbAdic < 20}
                    onClick={plusContribAdic}
                  >
                    +
                  </ButtonSimulationCalc>
                </div>
              </PercentualBox>
              <InfoContent>
                <div>
                  <p>Valor da contribuição adicional do participante: </p>
                  <strong>{formatValue(vlrCtbAdic)}</strong>
                </div>
              </InfoContent>
            </Collapse>
          </Content>
        </Form>
        <Collapse in={pctCtbSup > 0 || pctCtbAdic > 0}>
          <Button
            color="blue"
            onClick={() => handleClick('S')}
            width="medium"
            fontSize="small"
          >
            Quero simular minha aposentadoria
          </Button>
        </Collapse>
        <Button color="orange" onClick={() => handleClick('A')} width="large">
          Avançar
          <FiArrowRightCircle size={45} />
        </Button>
      </Container>
    </>
  )
}

export default BemVindo
